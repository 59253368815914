import React from 'react'

const Sentence = ({timeLabel, children, className}) => {

    console.log('children: ' + children)

    return (
    <>
    <style type="text/css">
      
    </style>
    <div id="sentence-border" className={className}>
        <div id="sentence-container">
            <span className="label">{timeLabel}</span>
            <div>
            {children?children.map((child,i)=>
                { 
                    if (typeof(child)=='object')
                        return ( <span key={i} className={`word-annotation ${className}`}>{child}</span>) 
                    else return <span key={i}>{child}</span>
                }) : ''}
            </div>
        </div>
    </div>
    </>)

}   

export default Sentence