import React from 'react'
import WordAnnotation from './components/WordAnnotation/WordAnnotation'

const database = (type) => {

const a = (text,tooltipText='') =>
{

        const key = 'w' + Math.floor(Math.random() * 100)
        return (<WordAnnotation key={key} tooltipText={tooltipText}>{text}</WordAnnotation>)

}


if (type=='examples') {

    
        return {

                0 : {

                        default : {
                                person:'them',
                                time:'present',
                                type:'simple',
                                perfect:false
                                },

                        values : {
                                me : {
                                past :{ 
                                        simple: 'I came from the land of the ice and snow',
                                        simpleperfect: 'I had come from the land of the ice and snow, when I arrived to new lands',
                                        continuous: 'I was coming from the land of the ice and snow last winter',
                                        continuousperfect: 'I had been coming from the land of the ice and snow for a while, when I finally arrived to new lands last winter',
                                },

                                present:{
                                        simple: 'I come from the land of the ice and snow',
                                        simpleperfect: 'I have come from the land of the ice and snow just now',
                                        continuous: 'I am coming from the land of the ice and snow',
                                        continuousperfect: 'I have been coming from the land of the ice and snow for a while now',
                                },

                                future :  { 
                                        simple: 'I will come from the land of the ice and snow',
                                        simpleperfect: 'I will have come from the land of the ice and snow by the next summer',
                                        continuous: 'I will be coming from the land of the ice and snow',
                                        continuousperfect: 'I will have been coming from the land of the ice and snow for some time by the the next summer',
                                        scheduled: 'I come from the land of the ice and snow at 5PM tomorrow',
                                        planning: 'I am coming from the land of the ice and snow next week',
                                        thinking: 'I going to come from the land of the ice and snow sometime next year',
                                },  

                                },

                                her : {
                                past :{ 
                                        simple: 'She came from the land of the ice and snow',
                                        simpleperfect: 'She had come from the land of the ice and snow, when she arrived to new lands',
                                        continuous: 'She was coming from the land of the ice and snow last winter',
                                        continuousperfect: 'She had been coming from the land of the ice and snow, when her ship got delayed last month',
                                },

                                present :{
                                        simple: 'She comes from the land of the ice and snow',
                                        simpleperfect: 'She has come from the land of the ice and snow five minutes ago',
                                        continuous: 'She is coming from the land of the ice and snow',
                                        continuousperfect: 'She has been coming from the land of the ice and snow for a while now',
                                },

                                future :  {
                                        simple: 'She will come from the land of the ice and snow',
                                        simpleperfect: 'She will have already come from the land of the ice and snow by the next year',
                                        continuous: 'She will be coming from the land of the ice and snow',
                                        continuousperfect: 'She will have been coming from the land of the ice and snow for a while by the next summer',
                                        }, 
                                }, 


                                them :{
                                past :{ 
                                        simple: 'We came from the land of the ice and snow',
                                        simpleperfect: 'We had already came from the land of the ice and snow, when we started fighting the horde',
                                        continuous: 'We were coming from the land of the ice and snow last winter',
                                        continuousperfect: 'We had been coming from the land of the ice and snow for a while, when we finally arrived to Valhalla',
                                },
                
                                present:{
                                        simple: 'We come from the land of the ice and snow',
                                        simpleperfect: 'We have come from the land of the ice and snow few minutes ago',
                                        continuous: 'We are coming from the land of the ice and snow',
                                        continuousperfect: 'We have been coming from the land of the ice and snow and we still have a long journey ahead',
                                },
                
                                future :  {
                                        simple: 'We will come from the land of the ice and snow',
                                        simpleperfect: 'We will have come from the land of the ice and snow when the fight starts',
                                        continuous: 'We will be coming from the land of the ice and snow',
                                        continuousperfect: 'We will have been coming from the land of the ice and snow when the fight starts',
                                } 
                                },  
                        }

                },

                1 : {

                        default : {
                                    person:'me',
                                    time:'past',
                                    type:'simple'
                                },
                
                        values : {
                                me : {
                                        past :{ 
                                                simple: ['I ', a('went','The action happened in the past. We use a simple past form of the verb'), ' to the gym ',a('yesterday','Often we use time expressions with the Past Simple, i.e., "yesterday," "last year," "in 2020," etc.')],
                                                continuous: ['I was ',a('working','We\'re emphasizing that the action was happening over an extended period in the past'),' from home last week'],
                                                perfect: ['I ',a('had stayed','Past Perfect is "the past of the past." The stay at the Airbnb happened during the trip that we had already completed'),' at an Airbnb during our last trip'],
                                                perfectcontinuous: ['I ',a('had been walking','Past Perfect is "the past of the past." The act of walking occurred before something else in the story'),' home when I saw Dieter Rams shelves through a window'],
                                        },
                        
                                        present:{
                                                simple: ['I have meetings ', a('every day','We often use Present Simple to talk about routine')],
                                                continuous: ['I\'m ',a('trying','This action is happening at the current moment'),' to stay focused'],
                                                perfect: ['I ',a('have been','We use Present Perfect to talk about actions that were in the past but are still relevant to the present'),' ',a('to Italy','In this example, even though we\'re not in Italy anymore, our experience will always be relevant during our lifetime'), ' a few times'],
                                                perfectcontinuous: ['I ',a('have been cooking','We started this activity in the past and continue doing it in the present'), ' this for two hours'],
                                        },
                        
                                        future :{ 
                                                intention: ['I\'m ',a('going to travel to France','We use "going to" + a simple present verb to describe plans or wishes for the future that are uncertain or without any arrangements in place: "I want to go to France but didn\'t plan anything yet"'), ' sometime this fall'],
                                                arrangement: ['I\'m ',a('travelling to France','We use Present Continuous when talking about future plans that are certain, or when we have made arrangements for them'),' this fall. Already got my tickets'],
                                                scheduled: ['My train ',a('arrives at 8:30 PM','We use Present Simple to talk about scheduled future events. Thus we should always include time')],
                                                momentary: ['I just decided, I ',a('will go','"Will" sounds natural when we talk about facts, beliefs, possibilities, predictions without evidence, or, in this example, decisions that we made in the moment of conversation'),' for a walk'],
                                        },                  
                                },
                                her : {
                                        past :{ 
                                                simple: ['She ', a('made','Action happened in the past. We use a simple past form of the verb'), ' a great progress yesterday'],
                                                continuous: ['He ', a('was running','The action happened over a continuous period in the past'), ' a marathon last fall'],
                                                perfect: ['He ',a('had worked','Past Perfect is "the past of the past." First, he worked on the project, and then he was re-arranged. All happened in the past'),' on this project before he was re-arranged'],
                                                perfectcontinuous: ['She ',a('was walking','Past Perfect is "the past of the past." The act of walking was happening before the rat incident'),' home when a rat bumped into her foot'],
                                        },
                        
                                        present:{
                                                simple: ['She really cares about her ',a('cat','She\'s got a cool cat so that\'s no surprise')],
                                                continuous: ['He is ',a('driving','This action is happening at the current moment'),' here as we speak'],
                                                perfect:['She ',a('has met','We use Present Perfect to talk about actions that were in the past but are still relevant to the present'),' her ',a('back in high school','Since it\'s Present Perfect, this means that they met in high school but keep in contact to this day')],
                                                perfectcontinuous: ['He ',a('has been doing','He started karate classes not long time ago and still attends them'),' karate lately'],
                                        },
                        
                                        future :  { 
                                                intention: ['He is ',a('going to quit','We use "going to" + a simple present verb to describe plans or wishes for the future, that are uncertain, or without any arrangements in place. He\'s thinking about quitting but didn\'t do anything about it yet'),' his job soon'],
                                                arrangement: ['He is ',a('qutting','We use Present Continuous to talk about future plans that are certain and we have made arrangements for them'),' his job tomorrow. He has just signed a new offer'],
                                                scheduled: ['She ',a('starts tomorrow at 9 AM','We use Present Simple to talk about future events that are scheduled. Thus it should always have a time')],
                                                momentary: ['She\'s only five, but I believe one day she ',a('will be','"Will" sounds natural when we talk about facts, predictions without evidence, possibilies, decisions that were made in a moment of conversation, or, in this example, beliefs'),' a great leader'],
                                        },                  
                                },

                                them : {
                                        past :{ 
                                                simple: ['We thought we were ',a('going to be','We use "going to" + simple present verb to talk about past events that we thought were going to happen, but did not'),' late to the boarding, but everything worked out'],
                                                continuous: ['They ', a('were living','We\'re emphasizing that the action was happening over an extended period in the past'), ' in Brooklyn back then'],
                                                perfect: ['They ',a('had requested','Past Perfect is "the past of the past." First, they got the permits, and only after they started the construction'),' all the necessary permits before they started the construction'],
                                                perfectcontinuous: ['We ',a('had been drinking','Past Perfect is "the past of the past." The drinking happened before another past event'),' when the police suddenly showed up'],
                                        },
                        
                                        present:{
                                                simple: ['We ',a('are','We are in this state permanently'),' all in this together'],
                                                continuous: ['They are ',a('discussing','This action is happening at the current moment'),' the strategy for the next quarter'],
                                                perfect: ['We ',a('have moved','We use Present Perfect to talk about actions that were in the past but are still relevant to the present. In this example, we imply that we\'re still living in Los Angeles'),' to Los Angeles last winter'],
                                                perfectcontinuous: ['They ',a('have been renovating','They started this activity in the past and continue doing it in the present'), ' it for ages'],
                                        },
                        
                                        future :  { 
                                                intention: ['At this rate, they are definitely ',a('going to win','We use "going to" + a simple present verb to make predictions based on some evidence. In this example, "at this rate" implies that we\'re seeing some proof of our statement')],
                                                arrangement: ['We are ',a('having a party','We use Present Continuous when talking about future plans that are certain, or when we have made arrangements for them'),' this Friday. We invited all our friends'],
                                                scheduled: ['They ',a('close at 7:30 PM','We use Present Simple to talk about scheduled future events. Thus we should always include time'), ' today'],
                                                momentary: ['We promised we ',a('will be','"Will" sounds natural when we talk about facts, beliefs, possibilities, decisions that we made in a moment of conversation, or, in this example, promises'),' there tomorrow'],
                                        },                  
                                },
                                
                        }
                
                    }    
        }

}

else if (type=='options')
{

        return {// I want to talk about...

                me : {
                        past : [ 
                                {id:'simple', value:'I did something'}, // usually used with time expressions, i.e. yesterday, in 2005, etc.
                                {id:'continuous', value:'I was doing something, but not now'},
                                {id:'perfect', value:'I had done something, before...'}, // past of the past
                                {id:'perfectcontinuous', value:'I had been doing something, before...'},  // past of the past
                        ],

                        present : [ 
                                {id:'simple', value:'I do something routinely'},
                                {id:'continuous', value:'I am doing something right now'},
                                {id:'perfect', value:'I have done something that is still relevant'}, // can be used with present time expression
                                {id:'perfectcontinuous', value:'I have been doing something up until now'},
                        ],

                        future : [ 
                                {id:'intention', value:'I think I\'m going to do something'}, // I'm going to travel to Spain
                                {id:'arrangement', value:'I\'ve made arrangements to do something'}, // I'm travelling to Spain ths weekend
                                {id:'scheduled', value:'I\'m scheduled to do something at a certain time'}, // I travel to Spain at 5 PM
                                {id:'momentary', value:'I just decided I\'ll do something'}, // I just decided I'll go to Spain // prediction without evidence
                        ],
                },  
                
                her : {
                        past : [ 
                                {id:'simple', value:'She did something'}, // usually used with time expressions, i.e. yesterday, in 2005, etc.
                                {id:'continuous', value:'He was doing something, but not now'},
                                {id:'perfect', value:'He had done something, before...'}, // past of the past
                                {id:'perfectcontinuous', value:'She had been doing something, before...'},  // past of the past
                        ],
        
                        present : [ 
                                {id:'simple', value:'She does something routinely'},
                                {id:'continuous', value:'He is doing something right now'},
                                {id:'perfect', value:'She has done something that is still relevant'}, // can be used with present time expression
                                {id:'perfectcontinuous', value:'He has been doing something up until now'},
                        ],
        
                        future : [ 
                                {id:'intention', value:'He is thinking of doing something'}, // I'm going to travel to Spain
                                {id:'arrangement', value:'He made arrangements to do something'}, // I'm travelling to Spain ths weekend
                                {id:'scheduled', value:'She is scheduled to do something'}, // I travel to Spain at 5 PM
                                {id:'momentary', value:'She is predicted to do something in a far future'}, // I just decided I'll go to Spain // prediction without evidence
                        ],
                },   
                
                them : {
                        past : [ 
                                {id:'simple', value:'We thought we would do something'}, // usually used with time expressions, i.e. yesterday, in 2005, etc.
                                {id:'continuous', value:'They were doing something, but not now'},
                                {id:'perfect', value:'They have done something, before...'}, // past of the past
                                {id:'perfectcontinuous', value:'We have been doing something, before...'},  // past of the past
                        ],
        
                        present : [ 
                                {id:'simple', value:'We do something routinely'},
                                {id:'continuous', value:'They are doing something right now'},
                                {id:'perfect', value:'We have done something that is still relevant'}, // can be used with present time expression
                                {id:'perfectcontinuous', value:'They have been doing something up until now'},
                        ],
        
                        future : [ 
                                {id:'intention', value:'They predicted to do something (with evidence)'}, // I'm going to travel to Spain
                                {id:'arrangement', value:'They made arrangements to do something'}, // I'm travelling to Spain ths weekend
                                {id:'scheduled', value:'They are scheduled to do something'}, // I travel to Spain at 5 PM
                                {id:'momentary', value:'They promised to do something'}, // I just decided I'll go to Spain // prediction without evidence
                        ],
                }, 


                // 
                // 1. intetions/wishes/plans without arrangemets/predictions - going to + Past Simple. "going to" used when there's no set date
                // 1. timetables - Present Simple
                // 2. arrangements - Present Continuous
                // 4. predictions without evidence/momentary decisions/promises/facts - will
                // 

        }


}


}

export default database

