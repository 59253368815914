import React from 'react'
import Favicon from "react-favicon"
import './App.css'
import Switcher from './components/Switcher/Switcher'
import Sentence from './components/Sentence/Sentence'
import Logo from './components/Logo/Logo'
import database from './data';


const App = () => {

  const personValues = [
    {id:'me', value:'Myself'},
    {id:'her', value:'Her or Him'},
    {id:'them', value:'Us or Them'},
  ]

  const timeValues = [
    {id:'past', value:'Past'},
    {id:'present', value:'Present'},
    {id:'future', value:'Future'},
  ]

  const timeLabels = {
    past: {
      simple : 'Past Simple',
      continuous: 'Past Continuous',
      perfect: 'Past Perfect',
      perfectcontinuous: 'Past Perfect Continuous'
    },
    present: {
      simple : 'Present Simple',
      continuous: 'Present Continuous',
      perfect: 'Present Perfect',
      perfectcontinuous: 'Present Perfect Continuous'
    },
    future: {
      intention : '[going to] + present simple form of a verb',
      arrangement: 'Present Continuous',
      scheduled: 'Present Simple',
      momentary: 'Future Simple'
    },
  }


  const data = database('examples')
  const sentenceSwitcherValues = database('options')

  const [activePerson,setActivePerson] = React.useState('me')
  const [activeTime,setActiveTime] = React.useState('past')
  const [activeSentence,setActiveSentence] = React.useState(data[1]['default']['type'])

  const theSentence = data[1].values[activePerson][activeTime][activeSentence]

  React.useEffect(()=>{

    // activeSentence IDs does not correspond for
    // Future vs other tenses, hence we're resetting
    // activeSentence to a default position
    
    if (activeTime=='future' && !theSentence) {
      setActiveSentence('intention')
    }
    else if (activeTime!='future' && !theSentence) {
      setActiveSentence('simple') 
    }
  },[activeTime,theSentence])

  const dynamicColorClass = `${activePerson}-${activeTime}`
  
 

  

  return (
    <>
    <div id="top-content">
      <Logo activePerson={activePerson} activeTime={activeTime}/>
      <h1>Which English Tense Should I Use?</h1>
      <h2>Learn how to use Present Perfect or any other English tenses</h2>
      <div id="filters">
        <Switcher active={activePerson} label="It's about..." values={personValues} onChange={setActivePerson}/>
        <Switcher active={activeTime} label="It happens in the..." values={timeValues} onChange={setActiveTime}/>
        <div className="sentence-and-checkbox-container">
          <Switcher active={activeSentence} label="I'm trying to say..." className="sentence-switcher" childClassName={dynamicColorClass} values={sentenceSwitcherValues[activePerson][activeTime]} onChange={setActiveSentence}/>
        </div>
      </div>
      <Sentence person={activePerson} timeLabel={timeLabels[activeTime][activeSentence]} className={dynamicColorClass}>{theSentence}</Sentence>
    </div>
    <footer>Created in NYC by <a href="https://igorsyvets.com/" target="_blank">Igor Syvets</a>. &copy; MMXXII</footer>
    </>
  )
}

export default App;
